<template>
  <div>
    
    <vue-good-table
      :columns="columns"
      :rows="rounds"
      theme="nocturnal"
     
    >
       <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  v-if="props.row.originalIndex == 0"  v-on:click="openSales(props.row.id)">
                <feather-icon
                  icon="PlayIcon"
                  class="mr-50"
                />
                <span>Iniciar sorteio em 3 minutos</span>
              </b-dropdown-item>
              <b-dropdown-item  v-if="props.row.status == 0"  v-on:click="openSales(props.row.id)">
                <feather-icon
                  icon="PlayIcon"
                  class="mr-50"
                />
                <span>Abrir Vendas</span>
              </b-dropdown-item>
              <b-dropdown-item  v-on:click="cloneRound(props.row.id)">
                <feather-icon
                  icon="RepeatIcon"
                  class="mr-50"
                />
                <span>Clonar</span>
              </b-dropdown-item>
                <b-dropdown-item >
                  <router-link :to="{ name: 'edit-round', params: { roundId: props.row.id }}">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Editar</span>
                  </router-link>
                </b-dropdown-item>
              <b-dropdown-item v-on:click="deleteRound(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Deletar</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      
    </vue-good-table>
    
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import {
   BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, 
    BDropdownItem,
    VueGoodTable
  },
  data () {
    return {
      columns: [
      {
        label: 'ID',
        field: 'id',
      },
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: 'Cartelas compradas',
        field: 'total_cards_in',
      },
      {
        label: 'Data prevista',
        field: 'start_date',
      },
      {
        label: 'Status',
        field: 'round_status.name',
      },
      {
        label: 'Ações',
        field: 'actions',
      }
      ],
      rounds: [],
    }
  },
  created() {
     this.getRounds();
  },
  methods: {
    getRounds(){
      this.$http.get('/room/open-rounds')
     .then(res => {
       this.rounds = res.data
       
      })
    },
    cloneRound(roundId) {
      this.$http
        .post('/room/clone-round', {
          roundId: roundId,
        })
        .then(response => {
          this.getRounds()
        })
    },
    openSales(roundId) {
      this.$http
        .post('/room/start-round', {
          roundId: roundId,
        })
        .then(res => {
          window.location.href = '/current-round/'
        })
    },
    deleteRound(roundId) {
      this.$http
        .post('/room/delete-round', {
          roundId: roundId,
        })
        .then(response => {
          this.getRounds()
        })
    }
  },
}
</script>

<style>

</style>
